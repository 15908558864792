import React, { useEffect, useRef } from "react";
import { FormData } from "./CoursesListingFilters.model";
import JobFiltersView from "@/mvcComponents/Commons/Jobs/JobFilterSection/JobFilters/JobFilters.view";
import styles from "./CoursesListingFilters.module.css";
import { useRouter } from "next/router";
import CoursesListingFiltersController from "./CoursesListingFilters.controller";

const CoursesListingFilters = ({
  setFilters,
  filters,
  setAppliedFilters,
  defaultFilters,
}) => {
  const router = useRouter();
  const coursesListingFiltersController = useRef(
    new CoursesListingFiltersController(router, setAppliedFilters)
  );

  useEffect(() => {
    if (router.isReady) {
      setFilters(
        coursesListingFiltersController.current.convertFiltersToObjects(
          coursesListingFiltersController.current.filterObjectByKeys(
            defaultFilters ? defaultFilters : router.query,
            Object.keys(FormData)
          )
        )
      );
      setAppliedFilters({
        ...coursesListingFiltersController.current.convertFiltersToObjects(
          coursesListingFiltersController.current.filterObjectByKeys(
            defaultFilters ? defaultFilters : router.query,
            Object.keys(FormData)
          )
        ),
      });

      coursesListingFiltersController.current.setFiltersForResultsPage(
        defaultFilters
      );
    }
  }, [router.isReady]);

  return (
    <div className={styles.courseFilters}>
      <JobFiltersView
        filterUIObj={Object.values(FormData)}
        setFilters={(name, value) => {
          setFilters((prevState) => ({ ...prevState, [name]: value }));
        }}
        clearFilters={() => {
          setAppliedFilters({});
          setFilters({});
          coursesListingFiltersController.current.setFiltersInQuery({});
        }}
        filtersRef={null}
        filters={filters}
        setFiltersInQuery={() => {
          coursesListingFiltersController.current.setFiltersInQuery(filters);
        }}
      />
    </div>
  );
};

export default CoursesListingFilters;
