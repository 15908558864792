import styled from "styled-components";
import { ColDiv, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { H2 } from "@/outscal-commons-frontend/Styled/TextStyles";

export const CardListWrapper = styled(ColDiv)`
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: calc(var(--screen-lg) + 20px);
  margin: auto;
  background-color: ${(p) => p.theme.colors.white};
`;

export const Heading = styled(H2)`
  font-size: var(--font-4xl);
  margin: ${(p) => !p.ctaText && "0 auto"};
  text-align: ${(p) => !p.ctaText && "center"};
  @media screen and (max-width: 600px) {
    padding: 5px;
  }
`;

export const Header = styled(RowDiv)`
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 4px;
  }
`;

export const CTA = styled.button`
  padding: 12px 28px;
  text-align: center;
  width: fit-content;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-sm);
  border: none;
  cursor: pointer;
  color: var(--black);
  transition: all 0.5s ease;
  background-color: var(--yellow);

  display: flex;
  align-items: center;
  flex: none;
  gap: 6px;

  @media screen and (max-width: 600px) {
    margin-bottom: 12px;
  }
`;

export const Subheading = styled.p`
  font-size: var(--font-lg);
  font-weight: var(--font-weight-medium);
  margin: 0 0 5px auto;
  text-align: center;
  line-height: 24px;
  color: var(--text-darker-grey);
`;
