import React, { useState, useEffect } from "react";
import { difficultyColorsMap } from "./AssessmentListingCard.model";
import styles from "./AssessmentListingCard.module.css";
import Image from "next/image";
import Link from "next/link";
import FeatureTags from "./FeatureTags.controller";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";
import useAuthActions from "@/store/actions/authAction";
// import AssessmentListingCardController from "./AssessmentListingCard.controller";
import { removeDecimals } from "@/utils/commonUtils";
import { NetworkManager } from "@/outscal-commons-frontend";

const AssessmentListingCard = ({
  image = {},
  level = [],
  title = "",
  uspTag = "",
  analyticsText,
  feeType = "",
  link = "",
  type = "",
  keys = {},
  scroll_id = null,
  premiumTagText = "",
  disableClick,
  isFeatured = false,
  hasCertificate = true,
  hasPlacement = false,
  contentCount = 0,
  contentIncludes = "",
  styleClass,
  styleClassType = "",
  skills = [],
  assessmentItem = {},
  failedAssessmentCTA = {},
}) => {
  const [tagText, setTagText] = useState();
  const [score, setScore] = useState(null);
  const { auth } = useAuthActions();
  const [userAssessmentResponse, setUserAssessmentResponse] = useState(null);

  // const assessmentListingCardController = new AssessmentListingCardController(
  //   showPopup,
  //   setUserAssessmentResponse,
  //   setScore,
  //   setTagText
  // );

  const getLatestResponse = async () => {
    if (auth?.token) {
      try {
        const resp = await NetworkManager.get(
          `/assessment-response/getLatestResponse/${assessmentItem._id}`
        );

        setUserAssessmentResponse(resp?.data);
      } catch (error) {
        console.log("error : ", error);
      }
    } else {
      setUserAssessmentResponse(null);
    }
  };

  useEffect(() => {
    // assessmentListingCardController.getLatestResponse(auth, assessmentItem);
    getLatestResponse();
  }, [auth]);

  const setScoreAndTagText = () => {
    if (!userAssessmentResponse) {
      setScore(null);
      setTagText();
      return;
    }

    if (userAssessmentResponse?.total_score >= 0) {
      let text = "FAIL";
      if (userAssessmentResponse?.total_score >= 80) {
        text = "PASS";
      }
      setScore(removeDecimals(userAssessmentResponse?.total_score));
      setTagText(text);
    }
  };

  const onAssessmentCardClick = (e, score) => {
    e.preventDefault();
    window.open(link);
  };

  useEffect(() => {
    // assessmentListingCardController.setScoreAndTagText(userAssessmentResponse);
    setScoreAndTagText();
  }, [userAssessmentResponse]);

  return (
    <div
      key={userAssessmentResponse}
      className={`${styles.root} ${
        isFeatured ? styles.featured : styles[`${styleClass}-root`]
      } ${styles[`${tagText}-assessment-root`]} ${
        !score ? styles.pointer : {}
      }`}
      id={scroll_id}
      data-analytics={[
        "CARD",
        `${tagText ? `${tagText}-` : ""}${analyticsText}`,
      ]}
      view-analytics={["CARD", analyticsText]}
      onClick={(e) => {
        // assessmentListingCardController.onAssessmentCardClick(
        //   e,
        //   score,
        //   assessmentItem,
        //   failedAssessmentCTA,
        //   userAssessmentResponse
        // )
        if (!score) {
          onAssessmentCardClick(e, score);
        }
      }}
    >
      <div className={styles.buttonWrapper}>
        <div className={styles.cardDetailsWrapper}>
          {image?.url && (
            <div className={styles.imageWrapper}>
              <p className={styles[`tagText-${tagText}`]}>{tagText}</p>
              <Image
                width={180}
                height={124}
                className={styles.cardImg}
                src={image.url}
                alt={title}
              />
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {feeType === "Free" && (
                <div className={styles.type}>{feeType}</div>
              )}
              {type && feeType === "Free" && (
                <div className={styles.type}>·</div>
              )}
              {type && <div className={styles.type}>{type}</div>}
              {contentCount && contentIncludes && (
                <>
                  <div className={styles.type}>·</div>
                  <div className={styles.type}>
                    {contentCount} {contentIncludes}
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "6px",
              }}
            >
              <Link href={link} onClick={disableClick}>
                <p
                  className={`${styles.title} ${
                    styles[`${styleClassType ? `${styleClassType}-` : ""}title`]
                  }`}
                >
                  {title}
                </p>
              </Link>
              <FeatureTags tags={keys?.keys} skills={skills} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  flexWrap: "wrap",
                }}
              >
                {level &&
                  (level.length >= 2
                    ? [level[0], level[level.length - 1]]
                    : level
                  ).map(
                    (difficulty, index) =>
                      difficulty?.label && (
                        <React.Fragment key={difficulty.id}>
                          <div className={`${styles.type} ${styles.iconText}`}>
                            <DynamicIcon
                              icon={difficultyColorsMap[difficulty.label]?.icon}
                            />
                            {difficulty.label}
                          </div>
                          {index === 0 && level.length >= 2 && (
                            <span className={styles.type}>to</span>
                          )}
                        </React.Fragment>
                      )
                  )}
                {hasCertificate && (
                  <>
                    <div className={styles.type}>·</div>
                    <div className={`${styles.type} ${styles.iconText}`}>
                      <DynamicIcon icon="PiCertificate" color="#8b3dff" />
                      Certificate Eligible
                    </div>
                  </>
                )}
                {hasPlacement && (
                  <>
                    <div className={styles.type}>·</div>
                    <div className={`${styles.type} ${styles.iconText}`}>
                      <DynamicIcon icon="BsCheck2Circle" color="#067519" />
                      Job Placement Support
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.scoreCol}>
          {score !== null && (
            <p
              className={
                styles[
                  `${tagText}-${
                    styleClassType ? `${styleClassType}-` : ""
                  }score`
                ]
              }
            >
              Your score: {score}/100
            </p>
          )}
          {score !== null && (
            <div className={styles.ctaWrapper}>
              <p
                onClick={(e) =>
                  // assessmentListingCardController.onAssessmentCardClick(
                  //   e,
                  //   score,
                  //   assessmentItem,
                  //   failedAssessmentCTA,
                  //   userAssessmentResponse
                  // )
                  onAssessmentCardClick(e, score)
                }
                className={`${styles.upskill} ${
                  styles[`${styleClassType ? `${styleClassType}-` : ""}upskill`]
                }`}
              >
                Retake
              </p>
              {score < 80 && (
                <a
                  href={score < 80 ? "/courses#Courses" : "#"}
                  className={`${styles.upskill} ${
                    styles[
                      `${styleClassType ? `${styleClassType}-` : ""}upskill`
                    ]
                  }`}
                >
                  Upskill
                </a>
              )}
            </div>
          )}
        </div>
      </div>
      {uspTag && (
        <div
          className={`${styles.uspTag} ${
            styles[
              isFeatured ? `${styleClass}-pill-featured` : `${styleClass}-pill`
            ]
          }`}
        >
          {uspTag}
        </div>
      )}
      {premiumTagText && (
        <div className={styles.premiumTag}>{premiumTagText}</div>
      )}
    </div>
  );
};

export default AssessmentListingCard;
