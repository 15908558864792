import React from "react";
import styles from "./ContentListingA.module.css";
import Link from "next/link";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import FeatureTags from "./FeatureTags.controller";
import Image from "next/image";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";
import { difficultyColorsMap, defaultProps } from "./ContentListingA.model";
import { useRouter } from "next/router";
import UseComponentVisibility from "@/hooks/useComponentVisibility";

const ContentListingA = ({
  image = defaultProps?.image,
  level = defaultProps?.level,
  benefits = defaultProps?.benefits,
  title = defaultProps?.title,
  uspTag = defaultProps?.uspTag,
  section_id = defaultProps?.section_id,
  keys = defaultProps?.keys,
  scroll_id = defaultProps?.scroll_id,
  premiumTagText = defaultProps?.premiumTagText,
  ctaLink = defaultProps?.ctaLink,
  isFeatured = defaultProps?.isFeatured,
  hasCertificate = defaultProps?.hasCertificate,
  hasPlacement = defaultProps?.hasPlacement,
  preTitle = defaultProps?.preTitle,
  showOn = defaultProps?.showOn,
  styleClass = defaultProps?.styleClass,
}) => {
  const router = useRouter();
  return (
    <UseComponentVisibility conditions={showOn}>
      <a
        className={`${styles.root} ${isFeatured && styles.featured}`}
        id={scroll_id}
        href={ctaLink}
        data-analytics={["CARD", `${section_id}-content-listing`]}
        view-analytics={["CARD", `${section_id}-content-listing`]}
      >
        <div
          className={`${styles.cardDetailsWrapper} ${
            styleClass == "mobile" && styles.mobileCardDetailsWrapper
          }`}
        >
          {image?.url && (
            <Image
              width={180}
              height={124}
              className={`${styles.cardImg} ${
                styleClass == "mobile" && styles.mobileCardImg
              }`}
              src={image.url}
              alt={title}
            />
          )}
          <div style={{ display: "flex", flexDirection: "column" }}>
            {preTitle && <div className={styles.type}>{preTitle}</div>}
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "6px",
              }}
            >
              <Text fontSize="xl" fontWeight="midBold">
                {title}
              </Text>
              <FeatureTags tags={keys?.keys || {}} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  flexWrap: "wrap",
                }}
              >
                {level &&
                  (level.length >= 2
                    ? [level[0], level[level.length - 1]]
                    : level
                  ).map(
                    (difficulty, index) =>
                      difficulty?.label && (
                        <React.Fragment key={difficulty.id}>
                          <div className={`${styles.type} ${styles.iconText}`}>
                            <DynamicIcon
                              icon={difficultyColorsMap[difficulty.label]?.icon}
                            />
                            {difficulty.label}
                          </div>
                          {index === 0 && level.length >= 2 && (
                            <span className={styles.type}>to</span>
                          )}
                        </React.Fragment>
                      )
                  )}
                {hasCertificate && (
                  <>
                    <div className={styles.type}>·</div>
                    <div className={`${styles.type} ${styles.iconText}`}>
                      <DynamicIcon icon="PiCertificate" color="#8b3dff" />
                      Certificate Eligible
                    </div>
                  </>
                )}
                {hasPlacement && (
                  <>
                    <div className={styles.type}>·</div>
                    <div className={`${styles.type} ${styles.iconText}`}>
                      <DynamicIcon icon="BsCheck2Circle" color="#067519" />
                      Job Placement Support
                    </div>
                  </>
                )}
                {benefits?.length > 0 &&
                  benefits.map((item, index) => (
                    <div className={`${styles.type} ${styles.iconText}`}>
                      <div className={styles.type}>·</div>
                      <DynamicIcon icon={item?.icon} color="#067519" />
                      {item?.text}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {uspTag && (
          <div
            className={`${styles.uspTag} ${
              styleClass == "mobile" && styles.mobileUspTag
            } ${isFeatured && `${styles.featured}`}`}
          >
            {uspTag}
          </div>
        )}
        {premiumTagText && (
          <div className={styles.premiumTag}>{premiumTagText}</div>
        )}
      </a>
    </UseComponentVisibility>
  );
};

export default ContentListingA;
