import React from "react";
import {
  CardListWrapper,
  Heading,
  Subheading,
  Header,
  CTA,
} from "./CoursesListing.styles";
import GridLayout from "@/mvcComponents/Commons/GridLayout/GridLayout.controller";
import ListingCard from "../../Cards/ListingCard/ListingCard.controller";
import CoursesListingFilters from "./CoursesListingFilters/CoursesListingFilters.view";
import ContentListingA from "../ContentListingA/ContentListingA.view";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";

const CoursesListingView = ({
  heading,
  data,
  onCtaClick,
  id,
  setFilters,
  filters,
  setAppliedFilters,
  isLoggedIn,
  assessments,
  failedAssessmentCTA,
  addAssessmentsToData,
  openInPopup,
  showCoursesFilters,
  subHeading,
  columns,
  showSubscription,
  defaultFilters,
  ctaIcon,
  ctaText,
}) => {
  return (
    <section id={`${id}-cta`}>
      <Header
        alignItems="center"
        justifyContent="space-between"
        padding={subHeading ? "0 0 8px 0" : "0"}
      >
        <Heading ctaText={ctaText}>{heading}</Heading>
        {ctaText && (
          <CTA
            data-analytics={[UIElements.BUTTON, `${id}`]}
            onClick={onCtaClick}
          >
            {ctaIcon && <DynamicIcon icon={ctaIcon} />}
            {ctaText}
          </CTA>
        )}
      </Header>
      {subHeading && <Subheading>{subHeading}</Subheading>}
      <CardListWrapper gap="12px">
        {showCoursesFilters && (
          <CoursesListingFilters
            defaultFilters={defaultFilters}
            setFilters={setFilters}
            filters={filters}
            setAppliedFilters={setAppliedFilters}
          />
        )}
        {showSubscription && <ContentListingA />}
        <GridLayout
          col={columns}
          name={id}
          gridGap={16}
          justifyContent="space-evenly"
          cardComponent={ListingCard}
          listData={addAssessmentsToData(data, assessments)}
          customCardData={{
            isLoggedIn,
            failedAssessmentCTA,
            openInPopup,
            columns,
          }}
        />
      </CardListWrapper>
    </section>
  );
};

export default CoursesListingView;
