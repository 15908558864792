import React, { useState } from "react";
import CoursesListingView from "./CoursesListing.view";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import useAuthActions from "@/store/actions/authAction";
import { FormData } from "./CoursesListingFilters/CoursesListingFilters.model";
import PopupService from "@/Logic/ClientLogic/Services/PopupService";
import { PRO_REGISTER_POPUP } from "@/store/actions/popupAction";
import { toast } from "react-toastify";
import useUserActions from "@/store/actions/UserProfileActions/userAction";

const CoursesListing = ({
  data,
  section_id,
  heading,
  subHeading = "",
  showOn,
  failedAssessmentCTA,
  openInPopup = false,
  showCoursesFilters = true,
  showSubscription = true,
  columns = 1,
  defaultFilters = {},
  ctaLink = "",
  ctaText = "",
  ctaIcon = "",
  listData,
}) => {
  const { auth } = useAuthActions();
  const { user } = useUserActions();
  const [filters, setFilters] = useState({});
  const { tableData = [], assessments = [] } = data || {};
  const convertFiltersToObjects = (obj = {}) => {
    const transformedFilters = {};

    if (Object.keys(obj).length < 1) {
      return transformedFilters;
    }

    for (const key in obj) {
      if (FormData[key] && FormData[key].optionsList) {
        const selectedIds = obj[key].split(",").map((id) => id.trim());
        const optionsList = FormData[key].optionsList;

        transformedFilters[key] = selectedIds
          .map((id) => {
            const option = optionsList.find((opt) => opt.id === id);
            return option ? { id: option.id, label: option.label } : null;
          })
          .filter(Boolean);
      } else {
        transformedFilters[key] = obj[key];
      }
    }

    return transformedFilters;
  };

  function filterCourses(array) {
    const userSlugs = new Set(
      user?.userCourseStatus?.map((ucs) => ucs?.course?.slug)
    );

    return array.filter((item) => {
      if (!["Course", "Specialization"].includes(item?.type)) {
        return true;
      }

      const hasValidSlug = item?.slug && userSlugs.has(item.slug);
      const hasPermissions = user?.userCourseStatus?.some(
        (ucs) =>
          ucs?.course?.slug === item?.slug && ucs?.permissions?.length > 0
      );

      return hasValidSlug && hasPermissions;
    });
  }

  const [appliedFilters, setAppliedFilters] = useState(
    convertFiltersToObjects(defaultFilters)
  );

  function sortByPriority(dataArray) {
    return dataArray?.sort((a, b) => b.priority - a.priority);
  }

  const onCtaClick = (e) => {
    if (!auth?.token) {
      e.preventDefault();
      PopupService.instance.showPopup(PRO_REGISTER_POPUP, {
        analyticsIntentText: section_id,
        registerFrom: section_id,
      });
    } else {
      if (ctaLink) {
        window.open(ctaLink);
      } else {
        toast.error("Oops, something went wrong");
      }
    }
  };

  const addAssessmentsToData = (data, assessments) => {
    const assessmentMap = assessments.reduce((map, assessment) => {
      map[assessment.slug] = assessment;
      return map;
    }, {});

    return data.map((item) => {
      const slug = item.slug;
      if (slug && assessmentMap[slug]) {
        return {
          ...item,
          assessmentItem: assessmentMap[slug],
        };
      }
      return item;
    });
  };

  return (
    <UseComponentVisibility conditions={showOn}>
      <section data-outscal-section className="section-listing">
        <CoursesListingView
          ctaLink={ctaLink}
          ctaIcon={ctaIcon}
          onCtaClick={onCtaClick}
          ctaText={ctaText}
          subHeading={subHeading}
          openInPopup={openInPopup}
          showCoursesFilters={showCoursesFilters}
          failedAssessmentCTA={failedAssessmentCTA}
          id={section_id}
          heading={heading}
          assessments={assessments}
          columns={columns}
          showSubscription={showSubscription}
          isLoggedIn={auth?.token}
          addAssessmentsToData={addAssessmentsToData}
          data={
            listData?.length
              ? listData
              : sortByPriority(filterCourses(tableData)).filter((item) => {
                  return Object?.keys(appliedFilters)?.every((key) => {
                    if (appliedFilters[key]?.length === 0) {
                      return true;
                    }
                    const filterValues = appliedFilters[key]?.map(
                      (filter) => filter?.label
                    );

                    if (key === "skills") {
                      return item?.skills?.data?.some((skill) =>
                        filterValues.includes(skill?.display_name)
                      );
                    }

                    if (key === "level") {
                      return item?.level?.some((diffLevel) =>
                        filterValues.includes(diffLevel?.label)
                      );
                    }

                    return filterValues.includes(item[key]);
                  });
                })
          }
          setFilters={setFilters}
          filters={filters}
          setAppliedFilters={setAppliedFilters}
          defaultFilters={
            Object.keys(defaultFilters).length > 0 ? defaultFilters : undefined
          }
        />
      </section>
    </UseComponentVisibility>
  );
};

export default CoursesListing;
